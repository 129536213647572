import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Link from 'gatsby-link'
import { FooterNav } from './footer.styled'

export default class Footer extends React.Component {
  public render(){
    return(<section>
      <Container>
        <Row>
          <Col>
          	<FooterNav>
	            © 2019 roothub s.r.o.
	          </FooterNav>
          </Col>
        </Row>
      </Container>
    </section>)
  }
}