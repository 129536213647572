import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import Search from '../components/search'
import Footer from '../components/footer'

import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from 'react-intl';

import 'intl';
import 'reset-css';
import 'bootstrap/dist/css/bootstrap.css';
import './fonts.css';
import './index.css';

const Layout = ({ children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
        }
      `}
      render={data => {

        // Skip build, Browsers only
        let url = "cs";
        if (typeof window !== 'undefined') {
          url = window.location.pathname;
        }

          
          const { langs, defaultLangKey } = data.site.siteMetadata.languages;
          const langKey = getCurrentLangKey(langs, defaultLangKey, url);
          const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
          const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
          return (
            <IntlProvider
              locale={langKey}
              messages={i18nMessages}
            >
              <div>
                <div className="pageContainer">
                  <Helmet
                    title="SPORT BUDDY"
                    meta={[
                      { name: 'description', content: 'GroupMe Support is here to help you with all of your All GroupMe product questions.' },
                      { name: 'keywords', content: 'GroupMe, help, support, customer support, groups, group, messaging, events, poll, polls' },
                    ]}
                  />
                  <Header langs={langsMenu} />
                  
                  {children}
                                    
                </div>


                <Footer langs={langsMenu} />
              </div>
            </IntlProvider>
          )
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  langKey: PropTypes.string,
}

export default Layout