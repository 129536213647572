import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/index'
import { Container, Row, Col } from 'reactstrap'
import { Box, SupportFeedback } from './home.styled'
import { FaqFragment } from '../fragments/faq'
import { HomePageFragment } from '../fragments/HomePageFragment'


import Articles from '../components/articles'
import Image from "../components/image"
import Logo from '../components/logo'

class HomeTemplate extends React.PureComponent<{data}> {

  public render() {

    const { indexPage, topFaqs, groupmeBasics, gettingStarted, groupmeSettings,
     troubleshooting, managingGroups, managingNotifications, sharing, coolFeatures, 
     calendarEvents, sms } = this.props.data
    const { frontmatter, html } = indexPage
    const { langKey } = this.props.pageContext;

    return (
      <Layout>
        <div
            className="home-page-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
      </Layout>
    )
  }
}

export default HomeTemplate

export const pageQuery = graphql`
  query($path: String!) {

    indexPage:markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...HomePageFragment
    },

    topFaqs: allMarkdownRemark(
      filter: {
        frontmatter: { 
          templateKey: { eq: "faq" }, 
          langKey: { eq: "en" }, 
          categories: { in: "top-faq" }
        }
      }
    ){
        totalCount
        edges {
          node {
            ...FaqFragment
          }
        }
      },

    groupmeBasics: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "groupme-basics" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    gettingStarted: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "getting-started" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    groupmeSettings: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "groupme-settings" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    troubleshooting: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "troubleshooting" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    managingGroups: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "managing-groups" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    managingNotifications: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "managing-notifications" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    sharing: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "sharing" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    coolFeatures: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "cool-features" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    calendarEvents: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "calendar-events" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    sms: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "sms" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

  }
`