import React from 'react'
import Link from 'gatsby-link'
import Logo from "./logo"
import Image from "./image"


import { Container, Row, Col } from 'reactstrap';


export default class Header extends React.Component {

  public render(){
    return(<section>
      <Container>
        <Row>
          <Col>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
              }}
            >
             
            </Link>
          </Col>
        </Row>
      </Container>
    </section>)
  }
}
